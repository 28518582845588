















































import { Component, Mixins } from 'vue-property-decorator';
import { View } from 'client-website-ts-library/plugins';
import { ImageSourceSet } from 'client-website-ts-library/types';

@Component({
  components: {
  },
})
export default class TenantFAQ extends Mixins(View) {
}
